import React, { MouseEvent } from 'react'
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport
} from '@mui/x-data-grid-pro'
import {
  Box, TextField, IconButton, Button
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

export interface GridToolbarSearchBoxProps {
  /** The current value of the search box */
  value: string;
  /** The function to execute when typing in the search box */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /** The function to execute when clearing the search box */
  clearSearch: () => void;
}

export interface SeeAllButtonProps {
  /**
   * onCLick handler for the See All button
   */
  onClick: (e: MouseEvent) => void
}

function GridToolbarSearchBox({
  value,
  onChange,
  clearSearch
}: GridToolbarSearchBoxProps) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0
      }}
    >
      <TextField
        variant="standard"
        value={value}
        onChange={onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: value ? 'visible' : 'hidden' }}
              onClick={clearSearch}
              data-testid="table-search-box-clear-search"
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          )
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          'data-testid': 'table-search-box'
        }}
        sx={{
          width: {
            xs: 1,
            sm: 'auto'
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          '& .MuiSvgIcon-root': {
            mr: 0.5
          },
          '& .MuiInput-underline:before': {
            borderBottom: 1,
            borderColor: 'divider'
          }
        }}
      />
    </Box>
  )
}

function ToolbarDivider() {
  return (
    <Box
      sx={{
        display: 'flex',
        bgcolor: '#E0E0E0',
        width: '2px',
        height: '15px',
        margin: 1
      }}
    />
  )
}

export function TableFooterButton({
  onClick
}: SeeAllButtonProps) {
  return <Button onClick={onClick}>See All</Button>
}

export default function TableToolbar({
  value,
  onChange,
  clearSearch
}: GridToolbarSearchBoxProps) {
  return (
    <Box paddingX={1} data-testid="table-toolbar">
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <ToolbarDivider />
        <GridToolbarFilterButton />
        <ToolbarDivider />
        <GridToolbarDensitySelector />
        <ToolbarDivider />
        <GridToolbarExport />
        <Box display="flex" bgcolor="pink" flexGrow={1} />
        <GridToolbarSearchBox
          value={value}
          onChange={onChange}
          clearSearch={clearSearch}
        />
      </GridToolbarContainer>
    </Box>
  )
}
