import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import { ArrowDropUp } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { RootState, AppThunkDispatch } from '../../../redux'
import { logoutThunk } from '../../../redux/users/actions'

function mapStateToProps(state: RootState) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchLogout: (...args: Parameters<typeof logoutThunk>) => dispatch(logoutThunk(...args))
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type UserMenuProps = StateProps & DispatchProps;

export function UserMenu({ user, dispatchLogout }: UserMenuProps) {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  const wrapperRef = useRef<HTMLDivElement | null>(null)

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
          setOpen(true)
        }}
        sx={{
          '&:hover': {
            cursor: 'pointer'
          },
          paddingLeft: 2
        }}
        data-testid="appbar-user-menu-box-wrapper"
        ref={wrapperRef}
      >
        <Typography variant="body1">{user.userRealName}</Typography>
        <IconButton>{open ? <ArrowDropUp /> : <ArrowDropDown />}</IconButton>
      </Box>
      <Menu
        open={open}
        id="user-menu"
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        PaperProps={{
          sx: {
            width: wrapperRef.current?.clientWidth
          }
        }}
        data-testid="appbar-user-menu-component"
      >
        <MenuItem
          onClick={() => {
            handleClose()
            if (user.sessionId && user.dataCenter && user.userId) {
              dispatchLogout(user.sessionId, user.dataCenter, user.userId)
            }
            navigate('/')
          }}
          data-testid="appbar-user-menu-item-logout"
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)
