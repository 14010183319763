import React from 'react'
import { Stack, Typography } from '@mui/material'
import config from '../../appConfig'
import VoyixLogo from '../../logos/VoyixLogo.png'

export default function SidebarBottomItem() {
  return (
    <Stack
      sx={{
        alignItems: 'center',
        backgroundColor: '#ffffff'
      }}
    >
      <Stack
        sx={{
          paddingX: 1.5,
          paddingTop: 2,
          marginTop: 0,
          marginBottom: 0,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          cursor: 'pointer'
        }}
      >
        <img
          src={VoyixLogo}
          alt="NCR Voyix"
          width={120}
        />
      </Stack>
      <Typography
        sx={{
          alignSelf: 'center',
          color: '#B9B9B9'
        }}
        data-testid="app-version"
        fontSize="10px"
      >
        v.
        {config.appVersion}
      </Typography>
    </Stack>
  )
}
