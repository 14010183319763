import { createSlice } from '@reduxjs/toolkit'
import { Deployment, DeploymentType } from '../../data/deploymentData'
import { RequestState } from '../common'
import {
  getActiveDeploymentData,
  getCompletedDeploymentData,
  getSavedDeploymentData,
  saveNewDeployment,
  deleteDeployment
} from './thunks'

export interface DeploymentDataState {
  /** The list of all Saved Deployments */
  savedDeployments: Deployment[];
  /** The list of all Active Deployments */
  activeDeployments: Deployment[];
  /** The list of all Completed Deployments */
  completedDeployments: Deployment[];
  /** Request states of setting and saving of data  */
  requestStates: {
    saveNewDeployment: RequestState;
    deleteSavedDeployment: RequestState;
  };
  /** The selected deployment */
  selectedDeployment: string | null;
}

const initialState: DeploymentDataState = {
  savedDeployments: [],
  activeDeployments: [],
  completedDeployments: [],
  requestStates: {
    saveNewDeployment: 'idle',
    deleteSavedDeployment: 'idle'
  },
  selectedDeployment: null
}

export const deploymentsSlice = createSlice({
  name: 'deployments',
  initialState,
  reducers: {
    setSavedDeploymentData: (state, action) => {
      state.savedDeployments = action.payload
    },
    setActiveDeploymentData: (state, action) => {
      state.activeDeployments = action.payload
    },
    setCompletedDeploymentData: (state, action) => {
      state.completedDeployments = action.payload
    },
    updateSavedDeployment: (state, action) => {
      state.savedDeployments = [action.payload, ...state.savedDeployments]
    },
    setSaveRequestState: (state, action) => {
      state.requestStates.saveNewDeployment = action.payload
    },
    setDeleteSavedDeploymentStatus: (state, action) => {
      state.requestStates.deleteSavedDeployment = action.payload
    },
    setSelectedDeployment: (state, action) => {
      state.selectedDeployment = action.payload
    },
    removeDeployment: (state, action) => {
      if (action.payload.selectedDeploymentType === DeploymentType.Saved) {
        state.savedDeployments = state.savedDeployments.filter(
          (d) => d.id !== action.payload.id
        )
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSavedDeploymentData.fulfilled, (state, action) => {
      state.savedDeployments = action.payload
    })
    builder.addCase(getActiveDeploymentData.fulfilled, (state, action) => {
      state.activeDeployments = action.payload
    })
    builder.addCase(getCompletedDeploymentData.fulfilled, (state, action) => {
      state.completedDeployments = action.payload
    })
    builder.addCase(saveNewDeployment.pending, (state) => {
      state.requestStates.saveNewDeployment = 'pending'
    })
    builder.addCase(saveNewDeployment.rejected, (state) => {
      state.requestStates.saveNewDeployment = 'rejected'
    })
    builder.addCase(saveNewDeployment.fulfilled, (state) => {
      state.requestStates.saveNewDeployment = 'fulfilled'
    })
    builder.addCase(deleteDeployment.pending, (state) => {
      state.requestStates.deleteSavedDeployment = 'pending'
    })
    builder.addCase(deleteDeployment.rejected, (state) => {
      state.requestStates.deleteSavedDeployment = 'rejected'
    })
    builder.addCase(deleteDeployment.fulfilled, (state) => {
      state.requestStates.deleteSavedDeployment = 'fulfilled'
    })
  }
})

export default deploymentsSlice.reducer
export const {
  setSavedDeploymentData,
  setActiveDeploymentData,
  setCompletedDeploymentData,
  updateSavedDeployment,
  setSaveRequestState,
  setDeleteSavedDeploymentStatus,
  setSelectedDeployment,
  removeDeployment
} = deploymentsSlice.actions
