import axios, { AxiosRequestHeaders, AxiosResponse, CancelToken } from 'axios'
import appConfig from '../appConfig'
import { AppError, FuncResult } from './result'
import { AuthenticatedUser } from './authenticatedUser'
import { OptionalHeaders } from './optionalHeaders'

// todo: make abortSignal optional
type ApiRequest = (route: string, headers: AxiosRequestHeaders) => Promise<AxiosResponse>

export interface RequestArgs {
  route: string
  user: AuthenticatedUser
  cancelToken?: CancelToken,
  optionalHeaders?: OptionalHeaders
  validResponseStatusCodes?: number[]
}

export interface RequestArgsWithBody<T> extends RequestArgs {
  body?: T
}

export function _validateStatus() {
  return true
}

export const axiosClient = axios.create({
  baseURL: appConfig.backendServerURL,
  validateStatus: _validateStatus
})

const baseHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
}

function getAuthHeaders(user: AuthenticatedUser) {
  const { id, sessionId, dataCenter } = user
  const isEmpty: (text: string) => boolean = (text) => text == null || text.trim().length === 0

  if ([id, sessionId, dataCenter].some((t) => isEmpty(t))) {
    throw new Error('Missing authentication headers')
  }

  return {
    userid: id,
    sessionid: sessionId,
    datacenter: dataCenter
  }
}

async function makeRequest<TResult>(
  route: string,
  headers: AxiosRequestHeaders,
  request: ApiRequest,
  validResponseStatusCodes: number[] = [200, 201]
): Promise<FuncResult<TResult>> {
  try {
    const response = await request(route, { ...baseHeaders, ...headers })

    if (!validResponseStatusCodes.some((x) => response.status === x)) {
      const { error } = response.data
      return new AppError(
        `Failed to get ${route} : status=${response.status} error=${error}`
      )
    }
    return response.data as TResult
  } catch (e) {
    return new AppError(
      `An error occurred while trying to get data route=${route} error=${e}`
    )
  }
}

export async function get<T>(
  {
    route,
    user,
    cancelToken = undefined,
    optionalHeaders = {},
    validResponseStatusCodes = [200, 201]
  }: RequestArgs
): Promise<FuncResult<T>> {
  return makeRequest(
    route,
    {
      ...getAuthHeaders(user),
      ...optionalHeaders
    },
    async (route, headers) => axiosClient.get(route, { headers: { ...headers }, cancelToken }),
    validResponseStatusCodes
  )
}

/**
 * Makes a request to the backend to authenticate the user.
 */
export async function postLogin<TBody, TResult>(
  route: string,
  body: TBody,
  validResponseStatusCodes: number[] = [200, 201]
): Promise<FuncResult<TResult>> {
  return makeRequest(
    route,
    {},
    async (route, headers) => axiosClient.post(route, body, { headers: { ...headers } }),
    validResponseStatusCodes
  )
}

export async function post<TBody, TResult>(
  {
    route,
    body,
    user,
    cancelToken = undefined,
    optionalHeaders = {},
    validResponseStatusCodes = [200, 201]
  }: RequestArgsWithBody<TBody>
): Promise<FuncResult<TResult>> {
  return makeRequest(
    route,
    {
      ...getAuthHeaders(user),
      ...optionalHeaders
    },
    async (route, headers) => axiosClient.post(route, body, { headers: { ...headers }, cancelToken }),
    validResponseStatusCodes
  )
}

export async function removeBulk<TData, TResult>(
  {
    route,
    user,
    body,
    cancelToken = undefined,
    optionalHeaders = {},
    validResponseStatusCodes = [200, 201]
  }: RequestArgsWithBody<TData>
): Promise<FuncResult<TResult>> {
  return makeRequest(
    route,
    {
      ...getAuthHeaders(user),
      ...optionalHeaders
    },
    async (route, headers) => axiosClient.delete(
      route,
      {
        data: {
          ...body
        },
        headers: {
          ...headers
        },
        cancelToken
      }
    ),
    validResponseStatusCodes
  )
}

export async function remove<TResult>(
  {
    route,
    user,
    cancelToken = undefined,
    optionalHeaders = {},
    validResponseStatusCodes = [200, 201]
  }: RequestArgs
): Promise<FuncResult<TResult>> {
  return makeRequest(
    route,
    {
      ...getAuthHeaders(user),
      ...optionalHeaders
    },
    async (route, headers) => axiosClient.delete(route, { headers: { ...headers }, cancelToken }),
    validResponseStatusCodes
  )
}

export async function put<TBody, TResult>(
  {
    route,
    body,
    user,
    cancelToken = undefined,
    optionalHeaders = {},
    validResponseStatusCodes = [200, 201]
  }: RequestArgsWithBody<TBody>
): Promise<FuncResult<TResult>> {
  return makeRequest(
    route,
    {
      ...getAuthHeaders(user),
      ...optionalHeaders
    },
    async (route, headers) => axiosClient.put(route, body, { headers: { ...headers }, cancelToken }),
    validResponseStatusCodes
  )
}

export async function patch<TBody, TResult>(
  {
    route,
    body,
    user,
    cancelToken = undefined,
    optionalHeaders = {},
    validResponseStatusCodes = [200, 201]
  }: RequestArgsWithBody<TBody>
): Promise<FuncResult<TResult>> {
  return makeRequest(
    route,
    {
      ...getAuthHeaders(user),
      ...optionalHeaders
    },
    async (route, headers) => axios.patch(route, body, { headers: { ...headers }, cancelToken }),
    validResponseStatusCodes
  )
}
