import { AuthenticatedUser } from '../common/authenticatedUser'
import { AppError } from '../common/result'
import { patch, post } from '../common/upgradesApiClient'
import { Tag } from './tags'

/**
 * Definition of a company.
 * A company can contain numerous sites.
 */
export interface Company {
  /**  Name of the company */
  name: string;
  /** The id of the company */
  id: string;
  /** Display label of the company with name and id */
  label: string;
  /** All tags that can be added to this company's sites */
  allTags: Tag[];
}

interface NewSiteTagRequest {
  tagLabel: string
}

interface RenameTagRequest {
  tagId: string,
  tagLabel: string
}

interface RenameTagResponse {
  endpoint: string,
  message: string
}

/**
 * Creates a new site tag for the provided company. All new tags will have
 * its labels following sentence case, regardless of what is passed.
 *
 * Example: "this is a new TAG" will be "This is a new tag"
 *
 * Returns the create site tag as a Tag object.
 *
 * @param authUser user session information
 * @param tagLabel the display label for the new tag to create
 */
export async function createNewSiteTag(
  authUser: AuthenticatedUser,
  tagLabel: string
): Promise<Tag> {
  const response = await post<NewSiteTagRequest, {data: Tag}>(
    {
      route: '/tag',
      user: authUser,
      body: {
        tagLabel
      }
    }
  )

  if (response instanceof AppError) {
    throw new Error(response.errorMsg)
  }

  const { id, label } = response.data as Tag

  return {
    id,
    label
  }
}

/**
 * Renames a company's site tag with a new provided label.
 * The new label will always be sentence case regardless of the input.
 *
 * @param dataCenter The data center for this company
 * @param companyId The company id associated with the tag to rename
 * @param tagLabel The new label of the tag
 * @param tagId The id of the tag to rename
 * @returns true if successful, otherwise false
 */
export async function renameCompanySiteTag(
  authUser: AuthenticatedUser,
  tagLabel: string,
  tagId: string
) {
  const response = await patch<RenameTagRequest, RenameTagResponse>(
    {
      route: '/tag',
      body: {
        tagId,
        tagLabel
      },
      user: authUser
    }
  )

  if (response instanceof AppError) {
    throw new Error(response.errorMsg)
  }

  return true
}
