import { NavItemArray } from '@ncr-design-system/pattern-app-shell'
import { routes } from '../../routes'

/**
 * A version of routes that is mapped for specific usage in the AppShell's SidebarItems
 * Component props.
 */
export const sidebarRoutes: NavItemArray = routes
  .filter((route) => route.shouldShow !== undefined)
  .map((route) => ({
    title: route.title,
    href: route.path,
    icon: route.icon,
    shouldShow: route.shouldShow
  }))
