import React from 'react'
import { Box } from '@mui/material'
import { connect } from 'react-redux'
import { AppThunkDispatch, RootState } from '../../../redux'
import SelectCompanyDropdown from '../../SelectCompanyDropdown'
import { setSelectedCompany } from '../../../redux/company/slice'
import UserMenu from './UserMenu'

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchSetSelectedCompany: (
      ...args: Parameters<typeof setSelectedCompany>
    ) => dispatch(setSelectedCompany(...args))
  }
}

function mapStateToProps(state: RootState) {
  return {
    user: state.user
  }
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type AppBarProps = StateProps & DispatchProps;

export function Appbar({
  user,
  dispatchSetSelectedCompany
}: AppBarProps): JSX.Element {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-end"
      width="100%"
      gap={2}
    >
      <Box width="250px" display="flex">
        <SelectCompanyDropdown
          width="100%"
          disableUnderline
          onChange={(_e, value) => {
            if (user.userId !== null
              && user.sessionId !== null
              && user.dataCenter !== null
              && value) {
              dispatchSetSelectedCompany({
                userId: user.userId, sessionId: user.sessionId, dataCenter: user.dataCenter, company: value
              })
            }
          }}
        />
      </Box>
      <UserMenu />
    </Box>
  )
}
export default connect(null, mapDispatchToProps)(Appbar)
