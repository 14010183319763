import { CancelToken } from 'axios'
import { AuthenticatedUser } from '../common/authenticatedUser'
import {
  DeviceDetailResponse,
  SiteDeviceData,
  SiteData,
  MissingDevicesData
} from './siteReadinessData'
import { get } from '../common/upgradesApiClient'
import { AppError } from '../common/result'

export async function getSiteReadinessSites(authUser: AuthenticatedUser, companyId: string): Promise<SiteData[]> {
  const response = await get<SiteData[]>(
    {
      route: `readiness/companysites/${companyId}`,
      user: authUser
    }
  )

  if (response instanceof AppError) {
    throw new Error(response.errorMsg)
  }

  return response
}

export async function getSiteReadinessDevices(cancelToken: CancelToken, authUser: AuthenticatedUser, siteId: string): Promise<SiteDeviceData[]> {
  const response = await get<SiteDeviceData[]>(
    {
      route: `readiness/devices/${siteId}`,
      user: authUser,
      cancelToken
    }
  )

  if (response instanceof AppError) {
    throw new Error(response.errorMsg)
  }

  return response
}

export async function getSiteReadinessDevicesDrawer(cancelToken: CancelToken, authUser: AuthenticatedUser, siteId: string, deviceId: string): Promise<DeviceDetailResponse> {
  const response = await get<DeviceDetailResponse>(
    {
      route: `readiness/devices/${siteId}/${deviceId}`,
      user: authUser,
      cancelToken
    }
  )

  if (response instanceof AppError) {
    throw new Error(response.errorMsg)
  }

  return response
}

export async function getMissingDevices(cancelToken: CancelToken, authUser: AuthenticatedUser, siteId: string): Promise<MissingDevicesData> {
  const response = await get<MissingDevicesData>(
    {
      route: `readiness/missingdevices/${siteId}`,
      user: authUser,
      cancelToken
    }
  )

  if (response instanceof AppError) {
    throw new Error(response.errorMsg)
  }

  return response
}
