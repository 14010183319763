import config from './config.json'

interface Config {
  /** The current environment of the application */
  environment: string;
  /** The Material UI license key for commerical features */
  muiLicenseKey: string;
  /** The backend server URL */
  backendServerURL: string;
  /** The version of the App */
  appVersion: string;
}

export default config as unknown as Config
