import React from 'react'
import Stack from '@mui/material/Stack'
import { connect } from 'react-redux'
import Login from './components/Login'
import FailedLoginDialog from './components/FailedLoginDialog'
import { AppThunkDispatch, RootState } from '../../redux'
import { setLoginRequestState } from '../../redux/users/actions'

function mapStateToProps(state: RootState) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchSetLoginRequestState: (
      ...args: Parameters<typeof setLoginRequestState>
    ) => dispatch(setLoginRequestState(...args))
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type LoginPageProps = StateProps & DispatchProps;

export function LoginPage({
  user,
  dispatchSetLoginRequestState
}: LoginPageProps) {
  return (
    <>
      <FailedLoginDialog
        open={user.loginRequestState === 'rejected'}
        onClose={() => dispatchSetLoginRequestState('idle')}
      />
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ bgcolor: '#F5F5F5', width: '100%', minHeight: '100%' }}
      >
        <Login />
      </Stack>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
