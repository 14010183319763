import { AppThunkAction } from '../../redux'

export const ADD_HEART = 'ADD_HEART'
export const FETCH_HEARTS = 'FETCH_HEARTS'
export const FETCH_HEARTS_FAILED = 'FETCH_HEARTS_FAILED'

const heartEmoji = '❤️'

export function addHeart() {
  return { type: ADD_HEART, payload: { text: heartEmoji } }
}

export function fetchHearts(): AppThunkAction {
  return async (dispatch) => {
    try {
      const numberOfHearts = await Promise.resolve(
        Math.ceil(Math.random() * 5)
      )
      dispatch({
        type: FETCH_HEARTS,
        payload: { text: heartEmoji.repeat(numberOfHearts) }
      })
    } catch (error) {
      dispatch({
        type: FETCH_HEARTS_FAILED,
        payload: { text: 'FETCHED_FAILED' }
      })
    }
  }
}
