import { Breadcrumbs, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

/**
 * Represents a segment in the Breadcrumbs component
 */
export interface BreadcrumbSegment {
  /**
   * The path the breadcrumb segment should redirect the user on click (applies to all except the last segment).
   */
  path: string
  /**
   * The display text for the breadcrumb segment.
   */
  name: string
  /**
   * The order to display the breadcrumb segment.
   */
  order: number
  /**
   * (optional) Unique test ID for each breadcrumb for unit tests.
   */
  testId?: string
}

/**
 * BreadcrumbNavigation style options
 */
export interface BreadcrumbNavigationOptions {
  /**
   * Text color for the last breadcrumb.
   */
  textColor?: string
  /**
   * Hyperlink text color for breadcrumbs.
   */
  hyperlinkColor?: string
  /**
   * Current breadcrumbs in the application's state
   */
  breadcrumbs: BreadcrumbSegment[]
}

export function BreadcrumbNavigation(
  {
    textColor,
    hyperlinkColor,
    breadcrumbs
  }: Readonly<BreadcrumbNavigationOptions>
): JSX.Element {
  const navigate = useNavigate()
  return (
    <Breadcrumbs sx={{ color: textColor }}>
      {
        breadcrumbs.map((b, index) => {
          const isLast = breadcrumbs.length - 1 === index
          if (!isLast) {
            return (
              <Typography
                sx={{
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
                variant="overline"
                color={hyperlinkColor}
                onClick={() => {
                  navigate(`${b.path}`)
                }}
                key={b.path}
                data-testid={b.testId}
              >
                {b.name}
              </Typography>
            )
          }

          return (
            <Typography
              variant="overline"
              key={b.path}
              data-testid={b.testId}
            >
              {b.name}
            </Typography>
          )
        })
    }
    </Breadcrumbs>
  )
}

BreadcrumbNavigation.defaultProps = {
  textColor: 'black',
  hyperlinkColor: '#1B76D4'
}

export default connect(null)(BreadcrumbNavigation)
