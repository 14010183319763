import { AppThunkAction } from '..'
import { buildAuthenticatedUser, buildAuthenticatedUserFromState } from '../../common/authenticatedUser'
import { BreadcrumbSegment } from '../../components/BreadcrumbNavigation'
import {
  fetchAvailableNCRSolutionSets,
  fetchSavedSolutionSets,
  updateSavedSolutionSets,
  SolutionSet,
  deleteSavedSolutionSet
} from '../../data/solutionSet'
import { RequestState } from './reducers'

export const SET_AVAILABLE_NCR_SOLUTION_SETS_SUCCESS = 'solutionSets/setAvailableNCRSolutionSetSuccess'
export const SET_AVAILABLE_NCR_SOLUTION_SETS_FAILED = 'solutionSets/setAvailableNCRSolutionSetFailed'
export const SET_AVAILABLE_SAVED_SOLUTION_SETS_SUCCESS = 'solutionSets/setAvailableSavedSolutionSetsSuccess'
export const SET_AVAILABLE_SAVED_SOLUTION_SETS_FAILED = 'solutionSets/setAvailableSavedSolutionSetsFailed'
export const SET_SELECTED_SOLUTION_SET = 'solutionSets/setSelectedNCRSolutionSet'
export const SET_EDITABLE_SELECTED_SOLUTION_SET = 'solutionSets/setEditableSelectedNCRSolutionSet'
export const SET_REMOVE_REQUEST_STATE = 'solutionSets/setRemoveRequestState'
export const SET_SAVE_REQUEST_STATE = 'solutionSets/setSaveRequestState'
export const SET_FETCH_NCR_SOLUTIONSET_REQUEST_STATE = 'solutionSets/setFetchNCRSolutionSetRequestState'
export const SET_FETCH_SAVED_SOLUTIONSET_REQUEST_STATE = 'solutionSets/setFetchSavedSolutionSetRequestState'
export const SET_EDITABLE_SOLUTION_SET_BREADCRUMBS = 'solutionSets/setEditableSolutionSetBreadcrumbs'

export type SolutionSetsAction =
  | {
      type:
        | typeof SET_AVAILABLE_NCR_SOLUTION_SETS_SUCCESS
        | typeof SET_AVAILABLE_NCR_SOLUTION_SETS_FAILED;
      payload: { availableNCRSolutionSets: SolutionSet[] };
    }
  | {
      type:
        | typeof SET_AVAILABLE_SAVED_SOLUTION_SETS_SUCCESS
        | typeof SET_AVAILABLE_SAVED_SOLUTION_SETS_FAILED;
      payload: { availableSavedSolutionSets: SolutionSet[] };
    }
  | {
      type: typeof SET_SELECTED_SOLUTION_SET;
      payload: { selectedSolutionSet: SolutionSet | null };
    }
  | {
      type: typeof SET_EDITABLE_SELECTED_SOLUTION_SET;
      payload: { editableSelectedSolutionSet: SolutionSet | null };
    }
  | {
      type: typeof SET_REMOVE_REQUEST_STATE;
      payload: RequestState;
    }
  | {
      type: typeof SET_SAVE_REQUEST_STATE;
      payload: RequestState;
    }
  | {
      type: typeof SET_FETCH_NCR_SOLUTIONSET_REQUEST_STATE;
      payload: RequestState;
    }
  | {
      type: typeof SET_FETCH_SAVED_SOLUTIONSET_REQUEST_STATE;
      payload: RequestState;
    }
    | {
      type: typeof SET_EDITABLE_SOLUTION_SET_BREADCRUMBS,
      payload: { breadcrumbs: BreadcrumbSegment[] }
    }

export function setAvailableNCRSolutionSetsSuccess(
  availableNCRSolutionSets: SolutionSet[]
): SolutionSetsAction {
  return {
    type: SET_AVAILABLE_NCR_SOLUTION_SETS_SUCCESS,
    payload: {
      availableNCRSolutionSets
    }
  }
}
export function setAvailableNCRSolutionSetsFailed(): SolutionSetsAction {
  return {
    type: SET_AVAILABLE_NCR_SOLUTION_SETS_FAILED,
    payload: {
      availableNCRSolutionSets: []
    }
  }
}

export function setAvailableSavedSolutionSetsSuccess(
  availableSavedSolutionSets: SolutionSet[]
): SolutionSetsAction {
  return {
    type: SET_AVAILABLE_SAVED_SOLUTION_SETS_SUCCESS,
    payload: {
      availableSavedSolutionSets
    }
  }
}

export function setAvailableSavedSolutionSetsFailed(): SolutionSetsAction {
  return {
    type: SET_AVAILABLE_SAVED_SOLUTION_SETS_FAILED,
    payload: {
      availableSavedSolutionSets: []
    }
  }
}

export function setSelectedSolutionSet(
  solutionSet: SolutionSet | null
): SolutionSetsAction {
  return {
    type: SET_SELECTED_SOLUTION_SET,
    payload: {
      selectedSolutionSet: solutionSet
    }
  }
}

export function setEditableSelectedSolutionSet(
  solutionSet: SolutionSet | null
): SolutionSetsAction {
  return {
    type: SET_EDITABLE_SELECTED_SOLUTION_SET,
    payload: {
      editableSelectedSolutionSet: solutionSet
    }
  }
}

export function setFetchNCRApprovedSolutionSetRequestState(
  requestState: RequestState
) {
  return {
    type: SET_FETCH_NCR_SOLUTIONSET_REQUEST_STATE,
    payload: requestState
  }
}

export function getAvailableNCRSolutionSets(
  dataCenter: string,
  sessionId: string,
  userId: string,
  companyId: string
): AppThunkAction {
  return async (dispatch) => {
    try {
      dispatch(setFetchNCRApprovedSolutionSetRequestState('pending'))
      const authUser = buildAuthenticatedUser(sessionId, dataCenter, userId)
      const availableNCRSolutionSets = await fetchAvailableNCRSolutionSets(
        authUser,
        companyId
      )
      dispatch(setAvailableNCRSolutionSetsSuccess(availableNCRSolutionSets))
      dispatch(setFetchNCRApprovedSolutionSetRequestState('fulfilled'))
    } catch (error) {
      dispatch(setAvailableNCRSolutionSetsFailed())
      dispatch(setFetchNCRApprovedSolutionSetRequestState('rejected'))
    }
  }
}

/**
 * Sets the value of fetchSavedSolutionSetsRequestState state value
 */
export function setFetchSavedSolutionSetsRequestState(
  requestState: RequestState
): SolutionSetsAction {
  return {
    type: SET_FETCH_SAVED_SOLUTIONSET_REQUEST_STATE,
    payload: requestState
  }
}

/**
 * Redux thunk action to fetch the available solution sets for a given data center and
 * company.
 *
 * @param dataCenter The data center that the user is logged into
 * @param companyId The company's id that the user wants to fetch solution sets for
 */
export function getAvailableSavedSolutionSets(): AppThunkAction {
  return async (dispatch, getState) => {
    try {
      const { user, company } = getState()
      const authenticatedUser = buildAuthenticatedUserFromState(user)
      dispatch(setFetchSavedSolutionSetsRequestState('pending'))
      const availableSavedSolutionSets = await fetchSavedSolutionSets(
        authenticatedUser,
        company.selectedCompany?.id as string
      )
      dispatch(
        setAvailableSavedSolutionSetsSuccess(availableSavedSolutionSets)
      )
      dispatch(setFetchSavedSolutionSetsRequestState('fulfilled'))
    } catch (error) {
      dispatch(setAvailableSavedSolutionSetsFailed())
      dispatch(setFetchSavedSolutionSetsRequestState('rejected'))
    }
  }
}

/**
 * Sets the value of the saveRequestState state value
 */
export function setSaveRequestState(
  requestState: RequestState
): SolutionSetsAction {
  return {
    type: 'solutionSets/setSaveRequestState',
    payload: requestState
  }
}

/**
 * Saves the provided solution set to the user's bank of Saved Solution Sets.
 * Updates the backend database along with updating the current state of the
 * solution sets reducer.
 */
export function saveSolutionSet(
  solutionSet: SolutionSet,
  dataCenter: string,
  companyId: string,
  sessionId: string,
  userId: string
): AppThunkAction {
  return async (dispatch) => {
    try {
      dispatch(setSaveRequestState('pending'))
      const authUser = buildAuthenticatedUser(sessionId, dataCenter, userId)
      await updateSavedSolutionSets(solutionSet, authUser, companyId)
      dispatch(setSaveRequestState('fulfilled'))
      dispatch(getAvailableSavedSolutionSets()) // updates redux with the backend data
    } catch (error) {
      // Do nothing
      // TODO: Handle when saving fails
      dispatch(setSaveRequestState('rejected'))
    }
  }
}

export function setRemoveRequestState(
  requestState: RequestState
): SolutionSetsAction {
  return {
    type: SET_REMOVE_REQUEST_STATE,
    payload: requestState
  }
}

/**
 * Removes a solution set from the user's bank of Saved Solution Sets.
 * Updates the backend database and refetches all of the available saved solution
 * sets to update redux.
 */
export function removeSavedSolutionSet(
  solutionSet: SolutionSet,
  dataCenter: string,
  companyId: string,
  sessionId: string,
  userId: string
): AppThunkAction {
  return async (dispatch) => {
    try {
      dispatch(setRemoveRequestState('pending'))
      const authUser = buildAuthenticatedUser(sessionId, dataCenter, userId)
      await deleteSavedSolutionSet(solutionSet, authUser, companyId)
      dispatch(setRemoveRequestState('fulfilled'))
      dispatch(getAvailableSavedSolutionSets())
    } catch (error) {
      // TODO: Handle when removing fails
      dispatch(setRemoveRequestState('rejected'))
    }
  }
}

export function setEditableSolutionSetBreadcrumbs(
  breadcrumbs: BreadcrumbSegment[]
): SolutionSetsAction {
  return {
    type: SET_EDITABLE_SOLUTION_SET_BREADCRUMBS,
    payload: { breadcrumbs }
  }
}
