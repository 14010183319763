import { combineReducers, AnyAction } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistReducer
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { hearts } from '../components/HelloWorld/reducers'
import { userReducer } from './users/reducers'
import { solutionSetsReducer } from './solutionSets/reducers'
import companyReducer from './company/slice'
import { sitesReducer } from './sites/slice'
import deploymentsReducer from './deployments/slice'
import { scheduleOptionsReducer } from './scheduleOptions/slice'
import SiteReadinessReducer from './siteReadiness/slice'

const reducers = {
  /**
   * State of the hearts reducer. Contains a string
   * for how many hearts have been added.
   */
  hearts,
  /**
   * State of the user
   */
  user: userReducer,
  /**
   * State of the selected company
   */
  company: companyReducer,
  /**
   * State of the selected company's sites
   */
  sites: sitesReducer,
  /**
   * State of interactions with Solution Set elements in this application
   */
  solutionSets: solutionSetsReducer,
  /**
   * State of deployment data
   */
  deploymentData: deploymentsReducer,
  /**
   * State of the user chosen options for scheduling deployments
   */
  scheduleOptions: scheduleOptionsReducer,

  /**
   * State of the Site Readiness page
   */
  siteReadiness: SiteReadinessReducer
}
const rootReducer = combineReducers(reducers)

/**
 * Root State of the Redux Store, containing information for each of
 * the reducers and it's current state. All reducer's state can be accessed
 * from this Root State
 */
export type RootState = ReturnType<typeof rootReducer>;

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['scheduleOptions'], // any reducers to ignore/not persist
  stateReconciler: autoMergeLevel2
}
const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
})

/**
 * Dispatcher function to dispatch redux actions or thunks
 */
export type AppThunkDispatch = ThunkDispatch<RootState, unknown, AnyAction>;

/**
 * A thunk that returns a function that dispatches and action along with some defined
 * side effects.
 */
export type AppThunkAction<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;

export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
