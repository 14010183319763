import { NavigateFunction } from 'react-router-dom'
import { AppThunkAction } from '..'
import CMCClient from '../../data/cmcClient'
import { buildAuthenticatedUser } from '../../common/authenticatedUser'

export const LOGOUT = 'users/logout'
export const SET_USER_LOGIN_DATA = 'users/setUserLoginData'
export const SET_LOGIN_REQUEST_STATE = 'users/setLoginRequestState'

export type UsersAction =
  | {
      type: typeof LOGOUT;
    }
  | {
      type: typeof SET_LOGIN_REQUEST_STATE;
      payload: 'idle' | 'pending' | 'fulfilled' | 'rejected';
    }
  | {
      type: typeof SET_USER_LOGIN_DATA;
      payload: {
        isLoggedIn: boolean;
        userId: string | null;
        userRealName: string | null;
        isAdmin: boolean;
        sessionId: string | null;
        dataCenter: string | null;
      };
    };

/*
    // ! The following are action creators
*/

/**
 * Upon logging in, this will take in the successful login data of the user,
 * and update the redux values.
 *
 * @param loginData The user's login information on successful login.
 */
export function setUserLoginData(loginData: {
  userId: string | null;
  userRealName: string | null;
  isAdmin: boolean;
  sessionId: string | null;
  dataCenter: string | null;
}): UsersAction {
  return {
    type: 'users/setUserLoginData',
    payload: {
      isLoggedIn: true,
      userId: loginData.userId,
      userRealName: loginData.userRealName,
      isAdmin: loginData.isAdmin,
      sessionId: loginData.sessionId,
      dataCenter: loginData.dataCenter
    }
  }
}

/**
 * Sets the current status of the Command Center (CMC) login request
 */
export function setLoginRequestState(
  requestState: 'idle' | 'pending' | 'fulfilled' | 'rejected'
): UsersAction {
  return {
    type: SET_LOGIN_REQUEST_STATE,
    payload: requestState
  }
}

export function logout(): UsersAction {
  return { type: LOGOUT }
}

/**
 * ! the following are thunks
 */

/**
 * Thunk action to call the CMC API to logout the user's current session.
 */
export function logoutThunk(
  sessionId: string,
  dataCenter: string,
  userId: string
): AppThunkAction {
  return async (dispatch) => {
    try {
      const authUser = buildAuthenticatedUser(sessionId, dataCenter, userId)
      await CMCClient.logout(authUser)
    } catch (e) {
      // TODO: Handle when logout call fails
    }
    dispatch(logout())
  }
}

/**
 * Logs in a user with the provided credentials. If it was successful, then the provided
 * navigation hook is used to push the user to the home page.
 *
 * @param userId The userId of the user, maps to the username on the front end component
 * @param password The password of the user
 * @param twoFactorToken The Google Authenticator 6 digit code, maps to the security code on the front end component
 * @param dataCenter The data center to log into
 * @param navigate The useNavigate react hook instance
 */
export function loginUser(
  userId: string,
  password: string,
  twoFactorToken: string,
  dataCenter: string,
  navigate: NavigateFunction
): AppThunkAction {
  return async (dispatch) => {
    try {
      dispatch(setLoginRequestState('pending'))
      const { data } = await CMCClient.login(
        userId,
        password,
        twoFactorToken,
        dataCenter
      )
      dispatch(setLoginRequestState('fulfilled'))
      dispatch(
        setUserLoginData({
          userId: data.UserId,
          userRealName: data.RealName,
          sessionId: data.SessionGuid,
          isAdmin: true, // TODO: Will need to implement roles later,
          dataCenter
        })
      )
      navigate('/select-company')
      dispatch(setLoginRequestState('idle'))
    } catch (error) {
      dispatch(setLoginRequestState('rejected'))
    }
  }
}
