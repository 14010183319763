import { createAsyncThunk } from '@reduxjs/toolkit'
import { v4 } from 'uuid'
import { RootState } from '..'
import { buildAuthenticatedUserFromState } from '../../common/authenticatedUser'
import { get, post, remove } from '../../common/upgradesApiClient'
import { Deployment, Site, DeploymentType } from '../../data/deploymentData'
import { updateSavedDeployment, removeDeployment } from './slice'

const mockSolutionSet = {
  id: '1',
  name: 'Solution Set 1',
  description: '',
  date: '02/23/2022 06:09:31',
  type: '',
  releaseContentProfile: '',
  enhancementReleaseGuide: '',
  products: {
    'Product 1': '1.0.0'
  }
}

const buildMockSites = (numberOfSites: number): Site[] => {
  const buildMockSite = (id: string): Site => ({
    id,
    name: `Site ${id}`,
    devices: [
      {
        id,
        name: `Device ${id}`,
        type: 0,
        statuses: []
      }
    ]
  })
  return Array.from({ length: numberOfSites }, (v, k) => buildMockSite(k.toString()))
}

/**
 * Sends a GET request to fetch all of the currently active deployments.
 *
 * A deployment is described as active when the deployment has been scheduled and
 * either waiting for approval or has been approved.
 */
export async function fetchActiveDeployments(): Promise<Deployment[]> {
  return [
    {
      id: v4(),
      name: 'Deployment 4',
      createdDate: '2022-03-01 13:42:00',
      notes: '',
      solutionSet: mockSolutionSet,
      sites: buildMockSites(22),
      rebootAfterDeployment: true,
      createdBy: {
        id: '1',
        name: 'Admin 1'
      },
      scheduledBy: {
        id: '3',
        name: 'Admin 3'
      },
      schedule: {
        startDate: '04/23/2022 06:09:31',
        timeType: 2,
        time: '10:30'
      }
    },
    {
      id: v4(),
      name: 'Deployment 5',
      createdDate: '2022-03-02 13:42:00',
      notes: '',
      solutionSet: mockSolutionSet,
      sites: buildMockSites(42),
      rebootAfterDeployment: false,
      createdBy: {
        id: '2',
        name: 'Admin 2'
      },
      scheduledBy: {
        id: '3',
        name: 'Admin 3'
      },
      schedule: {
        startDate: '04/22/2022 06:09:31',
        timeType: 3
      },
      approval: {
        user: {
          id: '1',
          name: 'Admin 1'
        },
        approved: true,
        timestamp: '04/22/2022 06:09:31'
      }
    },
    {
      id: v4(),
      name: 'Deployment 6',
      createdDate: '2022-03-03 13:42:00',
      notes: 'This is a really important deployment',
      solutionSet: mockSolutionSet,
      sites: buildMockSites(33),
      rebootAfterDeployment: false,
      createdBy: {
        id: '1',
        name: 'Admin 1'
      },
      scheduledBy: {
        id: '3',
        name: 'Admin 3'
      },
      schedule: {
        startDate: '04/27/2022 06:09:31',
        timeType: 1
      }
    }
  ]
}

/**
 * Sends a GET request to fetch all of the completed deployments.
 *
 * A deployment is described as completed when the deployment has completed
 * its download and installation process on all associated sites. Or when the deployment approval
 * has been denied. Or when all the sites have failed to complete the deployment.
 */
export async function fetchCompletedDeployments(): Promise<Deployment[]> {
  return [
    {
      id: v4(),
      name: 'Deployment 7',
      createdDate: '2022-04-01',
      notes: '',
      solutionSet: mockSolutionSet,
      sites: buildMockSites(22),
      rebootAfterDeployment: true,
      createdBy: {
        id: '1',
        name: 'Admin 1'
      },
      scheduledBy: {
        id: '3',
        name: 'Admin 3'
      },
      schedule: {
        startDate: '04/23/2022 06:09:31',
        timeType: 2,
        time: '10:30'
      },
      approval: {
        user: {
          id: '2',
          name: 'Admin 2'
        },
        approved: false,
        timestamp: '04/22/2022 06:09:31'
      }
    },
    {
      id: v4(),
      name: 'Deployment 8',
      createdDate: '2022-04-01',
      notes: '',
      solutionSet: mockSolutionSet,
      sites: buildMockSites(42),
      rebootAfterDeployment: false,
      createdBy: {
        id: '2',
        name: 'Admin 2'
      },
      scheduledBy: {
        id: '3',
        name: 'Admin 3'
      },
      schedule: {
        startDate: '04/22/2022 06:09:31',
        timeType: 3
      },
      approval: {
        user: {
          id: '2',
          name: 'Admin 2'
        },
        approved: true,
        timestamp: '04/23/2022 06:09:31'
      }
    },
    {
      id: v4(),
      name: 'Deployment 9',
      createdDate: '2022-04-01',
      notes: 'This is a really important deployment',
      solutionSet: mockSolutionSet,
      sites: buildMockSites(33),
      rebootAfterDeployment: false,
      createdBy: {
        id: '1',
        name: 'Admin 1'
      },
      scheduledBy: {
        id: '3',
        name: 'Admin 3'
      },
      schedule: {
        startDate: '04/27/2022 06:09:31',
        timeType: 1
      },
      approval: {
        user: {
          id: '2',
          name: 'Admin 2'
        },
        approved: true,
        timestamp: '04/25/2022 06:09:31'
      }
    }
  ]
}

export const getSavedDeploymentData = createAsyncThunk(
  'deployments/getSavedDeploymentStatus',
  async (args, { getState }) => {
    const { user, company } = getState() as RootState
    const authenticatedUser = buildAuthenticatedUserFromState(user)

    const removedDeployment = await get<Deployment[]>(
      {
        route: '/deployment',
        user: authenticatedUser,
        optionalHeaders: {
          companyid: company.selectedCompany?.id ?? ''
        }
      }
    )

    if (removedDeployment instanceof Error) {
      throw removedDeployment
    }

    return removedDeployment
  }
)

export const getActiveDeploymentData = createAsyncThunk(
  'deployments/getActiveDeploymentDataStatus',
  async () => fetchActiveDeployments()
)

export const getCompletedDeploymentData = createAsyncThunk(
  'deployments/getCompletedDeploymentDataStatus',
  async () => fetchCompletedDeployments()
)

export const saveNewDeployment = createAsyncThunk(
  'deployments/saveNewDeploymentStatus',
  async (deployment: Deployment, { getState, dispatch }) => {
    const { user, company } = getState() as RootState
    const response = await post(
      {
        route: '/deployment',
        body: deployment,
        user: buildAuthenticatedUserFromState(user),
        optionalHeaders: {
          companyid: company.selectedCompany?.id ?? ''
        }
      }
    )
    if (response instanceof Error) {
      throw response
    }
    dispatch(updateSavedDeployment(deployment))
  }
)

export const deleteDeployment = createAsyncThunk(
  'deployments/deleteDeployment',
  async (
    args: {
      selectedDeploymentType: DeploymentType
      id: string
    },
    { getState, dispatch }
  ) => {
    const { user, company } = getState() as RootState
    const authenticatedUser = buildAuthenticatedUserFromState(user)

    const removedDeployment = await remove<Deployment[]>(
      {
        route: `/deployment/${args?.id}`,
        user: authenticatedUser,
        optionalHeaders: {
          companyid: company.selectedCompany?.id ?? ''
        }
      }
    )

    if (removedDeployment instanceof Error) {
      throw removedDeployment
    }

    dispatch(removeDeployment(args))
    return removedDeployment
  }
)
