import {
  LOGOUT,
  UsersAction,
  SET_LOGIN_REQUEST_STATE,
  SET_USER_LOGIN_DATA
} from './actions'

export interface UserState {
  /** Whether or not the current user is logged in */
  isLoggedIn: boolean;
  /** Whether or not the current user is an admin */
  isAdmin: boolean;
  /** The current sessionId of the logged in user */
  sessionId: string | null;
  /** The userId of the logged in user */
  userId: string | null;
  /** The real name of the user */
  userRealName: string | null;
  /** The data center this user is logged into */
  dataCenter: string | null;
  /** The current state of a login request */
  loginRequestState: 'idle' | 'pending' | 'fulfilled' | 'rejected';
}

export function userReducer(
  state: UserState = {
    isLoggedIn: false,
    isAdmin: false,
    sessionId: null,
    userId: null,
    userRealName: null,
    dataCenter: null,
    loginRequestState: 'idle'
  },
  action: UsersAction
): UserState {
  switch (action.type) {
    case SET_LOGIN_REQUEST_STATE:
      return { ...state, loginRequestState: action.payload }
    case SET_USER_LOGIN_DATA:
      return { ...state, ...action.payload }
    case LOGOUT:
      return {
        isLoggedIn: false,
        isAdmin: false,
        sessionId: null,
        userId: null,
        userRealName: null,
        dataCenter: null,
        loginRequestState: 'idle'
      }
    default:
      return state
  }
}
