import React from 'react'
import { Stack, Typography } from '@mui/material'
import { connect } from 'react-redux'
import { RootState } from '../../../redux'
import { SuccessState } from '../../../data/siteReadinessData'

function mapStateToProps(state: RootState) {
  return {
    deviceInfo: state.siteReadiness.deviceInfo
  }
}

type StateProps = ReturnType<typeof mapStateToProps>
type DeviceSummaryProps = StateProps

export function SiteReadinessDeviceSummary({
  deviceInfo
} : Readonly<DeviceSummaryProps>): JSX.Element {
  if (deviceInfo?.deviceDetail == null || deviceInfo?.deviceDetailSummary == null) {
    return (
      <Typography />
    )
  }

  const { deviceDetail, deviceDetailSummary } = deviceInfo

  return (
    <Stack>
      <Stack>
        <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
          Summary
        </Typography>
        {(deviceDetail.isPrereqReady) && (
          <Typography variant="body1" color="green" paddingTop={1}>
            HW/SW Prerequisites&nbsp;
            {(deviceDetail.isUpgradeReady) && (
              <span style={{ color: 'green' }}>and Upgrade Readiness </span>
            )}
            pass for the device.
          </Typography>
        )}
        {(!deviceDetail.isPrereqReady) && (
          <Typography variant="body1" color="red" paddingTop={1}>
            HW/SW Prerequisites&nbsp;
            {(!deviceDetail.isUpgradeReady) && (
              <span style={{ color: 'red' }}>and Upgrade Readiness </span>
            )}
            fail for the device.
          </Typography>
        )}
      </Stack>
      {(deviceDetailSummary.uaVersion === SuccessState.Failure || deviceDetailSummary.uaStatus === SuccessState.Failure) && (
        <Stack>
          <Typography variant="body1" fontWeight="bold" paddingTop={2}>
            Upgrade readiness failure:
          </Typography>
          {(deviceDetailSummary.uaVersion === SuccessState.Failure) && (
            <Typography variant="body1" color="red" paddingTop={1}>
              Upgrade Agent is not installed on this device.
            </Typography>
          )}
          {(deviceDetailSummary.uaStatus === SuccessState.Failure) && (
            <Typography variant="body1" color="red" paddingTop={1}>
              Upgrade Agent is not running on this device.
            </Typography>
          )}
        </Stack>
      )}
      {(deviceDetailSummary.osVersion === SuccessState.Failure
        || deviceDetailSummary.osName === SuccessState.Failure
        || deviceDetailSummary.osBitness === SuccessState.Failure
        || deviceDetailSummary.sqlBitness === SuccessState.Failure
      ) && (
        <Stack>
          <Typography variant="body1" fontWeight="bold" paddingTop={2}>
            Software Failure Message:
          </Typography>
          {(deviceDetailSummary.osName === SuccessState.Failure
          || deviceDetailSummary.osVersion === SuccessState.Failure) && (
            <Stack>
              <Typography variant="body1" color="red" paddingTop={1}>
                This OS does not meet the minimum requirements for Upgrade.
              </Typography>
              <Typography variant="body1" color="red" paddingLeft={1}>
                Supported OS:
              </Typography>
              <Typography variant="body1" color="red" paddingLeft={2}>
                • Windows 10 version 1607 or higher
                • Windows Server 2016 or higher
              </Typography>
            </Stack>
          )}
          {(deviceDetailSummary.osBitness === SuccessState.Failure) && (
            <Typography variant="body1" color="red" paddingTop={1}>
              This OS is not 64-bit.
            </Typography>
          )}
          {(deviceDetailSummary.sqlBitness === SuccessState.Failure) && (
            <Typography variant="body1" color="red" paddingTop={1}>
              This SQL Server is not 64-bit.
            </Typography>
          )}
        </Stack>
      )}
      {(deviceDetailSummary.ram === SuccessState.Failure
        || deviceDetailSummary.localDirSpace === SuccessState.Failure
        || deviceDetailSummary.iberDirSpace === SuccessState.Failure
      ) && (
        <Stack>
          <Typography variant="body1" fontWeight="bold" paddingTop={2}>
            Hardware Failure Message:
          </Typography>
          {(deviceDetailSummary.localDirSpace === SuccessState.Failure || deviceDetailSummary.iberDirSpace === SuccessState.Failure) && (
            <Typography variant="body1" color="red" paddingTop={1}>
              Your device does not meet the minimum disk space requirements.
              <br />
              &#40;BOH - 15 GB, FOH - 5GB&#41;
              <br />
              Please free disk space.
            </Typography>
          )}
          {(deviceDetailSummary.ram === SuccessState.Failure) && (
            <Typography variant="body1" color="red" paddingTop={1}>
              Your device does not meet the minimum RAM requirements.
            </Typography>
          )}
        </Stack>
      )}
      {deviceDetailSummary.posLicense === SuccessState.Failure && (
        <Stack>
          <Typography variant="body1" fontWeight="bold" paddingTop={2}>
            License Warning Message:
          </Typography>
          <Typography variant="body1" color="orange" paddingTop={1}>
            Make sure you have a valid POS license version before attempting to upgrade.
          </Typography>
        </Stack>
      )}
    </Stack>
  )
}

export default connect(mapStateToProps)(SiteReadinessDeviceSummary)
