import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type TimeType = 'MinEod' | 'SpecificTime' | 'ASAP' | 'Download' | '';

export interface TimeOptions {
  type: TimeType | undefined;
  minEod: string;
  time: string | null;
}
export interface ScheduleState {
  deploymentId: string | null;
  choosenSites: string[];
  date: string | null;
  timeOptions: TimeOptions | null;
}

const initialState: ScheduleState = {
  deploymentId: null,
  choosenSites: [],
  date: null,
  timeOptions: null
}

export const scheduleOptionsSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setScheduleOptionsDeploymentId: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.deploymentId = action.payload
    },
    setChoosenSites: (state, action: PayloadAction<string[]>) => {
      state.choosenSites = action.payload
    },
    setScheduleDate: (state, action: PayloadAction<string | null>) => {
      state.date = action.payload
    },
    setTimeOptions: (state, action: PayloadAction<TimeOptions>) => {
      state.timeOptions = action.payload
    }
  }
})

export const scheduleOptionsReducer = scheduleOptionsSlice.reducer
export const {
  setScheduleOptionsDeploymentId,
  setChoosenSites,
  setScheduleDate,
  setTimeOptions
} = scheduleOptionsSlice.actions
