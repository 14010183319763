import React from 'react'
import { Stack } from '@mui/material'
import SelectCompany from './components/SelectCompany'

export function SelectCompanyPage() {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      // #F5F5F5
      sx={{ bgcolor: '#F5F5F5', width: '100%', minHeight: '100%' }}
    >
      <SelectCompany />
    </Stack>
  )
}
