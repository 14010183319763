import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { RootState } from '../../redux'
import { Company } from '../../data/companyData'

export interface OwnProps {
  /** The width of the component */
  width?: string | number;
  /** It disables the underline below the TextField in Autocomplete */
  disableUnderline?: boolean;
  /** Even to check if company is selected/changed from the dropdown */
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: Company | null
  ) => void;
}

function mapStateToProps(state: RootState) {
  return {
    companyList: state.company.companyList,
    selectedCompany: state.company.selectedCompany
  }
}
type StateProps = ReturnType<typeof mapStateToProps>;
type SelectCompanyProps = StateProps & OwnProps;

const StyledTextField = styled(TextField)`
  /* default */
  .MuiInput-underline:before {
    border-bottom: transparent;
  }
  /* hover */
  && .MuiInput-underline:hover:before {
    border-bottom: 2px solid blue;
  }
  /* focused */
  .MuiInput-underline:after {
    border-bottom: 2px solid blue;
  }
`
export function SelectCompanyDropdown({
  width,
  disableUnderline,
  onChange,
  companyList,
  selectedCompany
}: SelectCompanyProps) {
  return (
    <Autocomplete
      // disablePortal
      data-testid="SelectedCompany"
      options={Object.values(companyList)}
      sx={{ width: { width } }}
      value={
        Object.values(companyList).find((c) => c.label === selectedCompany?.label) || null // default to null instead of undefined
      }
      renderInput={(params) => (disableUnderline === true ? (
        <StyledTextField
            /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...params}
          variant="standard"
          sx={{ input: { textAlign: 'right' } }}
          inputProps={{
            'data-testid': 'select-company-dropdown-input',
            ...params.inputProps
          }}
        />
      ) : (
        <TextField
            /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...params}
          variant="standard"
          label="Company"
          inputProps={{
            'data-testid': 'select-company-dropdown-input',
            ...params.inputProps
          }}
        />
      ))}
      onChange={onChange}
    />
  )
}

SelectCompanyDropdown.defaultProps = {
  width: '100%',
  disableUnderline: false,
  onChange: () => {
    // do nothing
  }
}

export default connect(mapStateToProps)(SelectCompanyDropdown)
