import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp'
import {
  Stack,
  Typography,
  Button,
  CircularProgress,
  Box
} from '@mui/material'
import { AppThunkDispatch, RootState } from '../../../../redux'
import {
  getCompanyList,
  setSelectedCompany
} from '../../../../redux/company/slice'
import SelectCompanyDropdown from '../../../../components/SelectCompanyDropdown'

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchSetSelectedCompany: (
      ...args: Parameters<typeof setSelectedCompany>
    ) => dispatch(setSelectedCompany(...args)),
    dispatchGetCompanyList: (...args: Parameters<typeof getCompanyList>) => dispatch(getCompanyList(...args))
  }
}

function mapStateToProps(state: RootState) {
  return {
    company: state.company,
    user: state.user
  }
}
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type SelectCompanyProps = StateProps & DispatchProps;

export function SelectCompany({
  user,
  company,
  dispatchSetSelectedCompany,
  dispatchGetCompanyList
}: SelectCompanyProps) {
  const navigate = useNavigate()

  useEffect(() => {
    // Force user to login if not logged in
    if (user.isLoggedIn === false) {
      navigate('/')
    }

    // Fetch all available companies on load
    if (
      user.userId !== null
      && user.sessionId !== null
      && user.dataCenter !== null
    ) {
      dispatchGetCompanyList({
        userId: user.userId,
        sessionId: user.sessionId,
        dataCenter: user.dataCenter
      })
    }
  }, [])

  const onContinue = () => {
    if (company.selectedCompany) {
      navigate('/solutionsets')
    }
  }
  return (
    <Stack
      justifyContent="center"
      spacing={2}
      sx={{
        alignItems: 'center',
        bgcolor: '#FFFFFF',
        maxWidth: '90%',
        width: '470px',
        paddingTop: 2,
        paddingBottom: 5,
        paddingX: 7,
        borderRadius: '8px',
        boxShadow: '0px 0px 0px 1px #E0E0E0'
      }}
    >
      <Typography
        sx={{
          color: '#000000',
          fontWeight: 500
        }}
        variant="subtitle1"
      >
        Choose a Company
      </Typography>
      <Typography variant="body2">
        Select a company from the dropdown. You can switch a company at any
        time.
      </Typography>

      {company.companiesRequestState === 'pending' && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingY: 4
          }}
          data-testid="select-company-circular-progress"
        >
          <CircularProgress size={38} />
        </Box>
      )}
      {company.companiesRequestState !== 'pending' && (
        <>
          <SelectCompanyDropdown
            onChange={(_e, value) => {
              if (
                user.userId !== null
                && user.sessionId !== null
                && user.dataCenter !== null
                && value) {
                dispatchSetSelectedCompany({
                  userId: user.userId, sessionId: user.sessionId, dataCenter: user.dataCenter, company: value
                })
              }
            }}
          />
          <Button
            disabled={company.selectedCompany === null}
            variant="contained"
            endIcon={<ArrowForwardSharpIcon />}
            fullWidth
            onClick={onContinue}
            data-testid="select-company-button"
          >
            Continue
          </Button>
        </>
      )}
    </Stack>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectCompany)
