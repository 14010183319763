import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

interface FailedLoginDialogProps {
  /** The condition to open this dialog */
  open: boolean;
  /** What to execute when closing the dialog */
  onClose?: () => void;
}

export default function FailedLoginDialog({
  open,
  onClose
}: FailedLoginDialogProps) {
  return (
    <Dialog data-testid="failed-login-dialog" open={open} fullWidth>
      <DialogTitle>Login Error</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Unable to authenticate Command Center user. Please try again or check
          your email for more details.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="failed-login-dialog-close-button"
          onClick={() => {
            if (onClose) {
              onClose()
            }
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

FailedLoginDialog.defaultProps = {
  onClose: () => {
    // Do nothing...
  }
}
