import { BreadcrumbSegment } from '../../components/BreadcrumbNavigation'
import { SolutionSet } from '../../data/solutionSet'
import {
  SolutionSetsAction,
  SET_AVAILABLE_NCR_SOLUTION_SETS_SUCCESS,
  SET_AVAILABLE_NCR_SOLUTION_SETS_FAILED,
  SET_AVAILABLE_SAVED_SOLUTION_SETS_SUCCESS,
  SET_AVAILABLE_SAVED_SOLUTION_SETS_FAILED,
  SET_SELECTED_SOLUTION_SET,
  SET_REMOVE_REQUEST_STATE,
  SET_SAVE_REQUEST_STATE,
  SET_EDITABLE_SELECTED_SOLUTION_SET,
  SET_FETCH_NCR_SOLUTIONSET_REQUEST_STATE,
  SET_FETCH_SAVED_SOLUTIONSET_REQUEST_STATE,
  SET_EDITABLE_SOLUTION_SET_BREADCRUMBS
} from './actions'

export type RequestState = 'idle' | 'pending' | 'fulfilled' | 'rejected';

export interface SolutionSetsState {
  /** The solution sets that the user has saved for themselves */
  availableSavedSolutionSets: SolutionSet[];
  /** The solution sets that are available as a part of NCR's Approved Solution Sets */
  availableNCRSolutionSets: SolutionSet[];
  /** The selected solution set from the table displaying the availableNCRSolutionSets */
  selectedSolutionSet: SolutionSet | null;
  /** The Selected solution set that can be edited */
  editableSelectedSolutionSet: SolutionSet | null;
  /** The state of the NCR approved solution sets fetch request */
  fetchNCRApprovedSolutionSetRequestState:
    | 'idle'
    | 'pending'
    | 'fulfilled'
    | 'rejected';
  fetchSavedSolutionSetRequestState:
    | 'idle'
    | 'pending'
    | 'fulfilled'
    | 'rejected';
  /** The state of the remove request */
  removeRequestState: RequestState;
  /** The state of the save request */
  saveRequestState: RequestState;
  solutionSetBreadcrumbs: BreadcrumbSegment[]
}

export function solutionSetsReducer(
  state: SolutionSetsState = {
    availableSavedSolutionSets: [],
    availableNCRSolutionSets: [],
    selectedSolutionSet: null,
    editableSelectedSolutionSet: null,
    fetchNCRApprovedSolutionSetRequestState: 'idle',
    fetchSavedSolutionSetRequestState: 'idle',
    removeRequestState: 'idle',
    saveRequestState: 'idle',
    solutionSetBreadcrumbs: []
  },
  action: SolutionSetsAction
): SolutionSetsState {
  switch (action.type) {
    case SET_AVAILABLE_NCR_SOLUTION_SETS_SUCCESS:
      return {
        ...state,
        availableNCRSolutionSets: action.payload.availableNCRSolutionSets
      }
    case SET_AVAILABLE_NCR_SOLUTION_SETS_FAILED:
      return {
        ...state,
        availableNCRSolutionSets: action.payload.availableNCRSolutionSets,
        selectedSolutionSet: null
      }
    case SET_SELECTED_SOLUTION_SET:
      return {
        ...state,
        selectedSolutionSet: action.payload.selectedSolutionSet
      }
    case SET_EDITABLE_SELECTED_SOLUTION_SET:
      return {
        ...state,
        editableSelectedSolutionSet: action.payload.editableSelectedSolutionSet
      }
    case SET_AVAILABLE_SAVED_SOLUTION_SETS_SUCCESS:
    case SET_AVAILABLE_SAVED_SOLUTION_SETS_FAILED:
      return {
        ...state,
        availableSavedSolutionSets: action.payload.availableSavedSolutionSets
      }
    case SET_REMOVE_REQUEST_STATE:
      return {
        ...state,
        removeRequestState: action.payload
      }
    case SET_SAVE_REQUEST_STATE:
      return {
        ...state,
        saveRequestState: action.payload
      }
    case SET_FETCH_NCR_SOLUTIONSET_REQUEST_STATE:
      return {
        ...state,
        fetchNCRApprovedSolutionSetRequestState: action.payload
      }
    case SET_FETCH_SAVED_SOLUTIONSET_REQUEST_STATE:
      return {
        ...state,
        fetchSavedSolutionSetRequestState: action.payload
      }
    case SET_EDITABLE_SOLUTION_SET_BREADCRUMBS:
      return {
        ...state,
        solutionSetBreadcrumbs: [...action.payload.breadcrumbs].sort((a, b) => a.order - b.order)
      }
    default:
      return state
  }
}
