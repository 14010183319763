import { Company } from './companyData'
import { Tag } from './tags'
import { Site } from './sites'
import { AuthenticatedUser } from '../common/authenticatedUser'
import { post, get, postLogin } from '../common/upgradesApiClient'
import { AppError } from '../common/result'

interface CmcLoginRequest {
  UserId: string,
  Password: string,
  TwoFactorToken: string,
  dataCenter: string
}

export interface CmcLoginResponse {
  endpoint: string;
  data: {
    SessionGuid: string;
    Eula: boolean;
    UserUniqueId: number;
    UserId: string;
    RealName: string;
  };
}

interface CmcLogoutRequest {
  sessionId: string,
  dataCenter: string
}

interface CmcLogoutResponse {
  endpoint: string,
  message: string
}

/**
 * A client to communicate with CMC REST API
 */
export default class CMCClient {
  /**
   * Logs in a user with provided credentials into the provided CMC dataCenter and returns
   * back the user's session information for subsequent requests. Note that after some inactivity,
   * the session information will be expired and require another login.
   *
   * @param userId The user's Id/username to log in with
   * @param password The user's password to log in with
   * @param twoFactorToken The user's two factor authentication token from Google Authenticator
   * @param dataCenter The data center that this user wants to log into
   */
  static async login(
    userId: string,
    password: string,
    twoFactorToken: string,
    dataCenter: string
  ): Promise<CmcLoginResponse> {
    const response = await postLogin<CmcLoginRequest, CmcLoginResponse>(
      '/cmclogin',
      {
        UserId: userId,
        Password: password,
        TwoFactorToken: twoFactorToken,
        dataCenter
      }
    )

    if (response instanceof AppError) {
      throw new Error(response.errorMsg)
    }
    return response
  }

  /**
   * Logs out a user with provided their currently active session ID from the data center they were
   * logged into and returns back a successful logout or an error response.
   *
   * @param sessionId The user's currently active session ID
   * @param dataCenter The data center that this user wants to logout of
   */
  static async logout(authUser: AuthenticatedUser) {
    const response = await post<CmcLogoutRequest, CmcLogoutResponse>(
      {
        route: '/cmclogout',
        user: authUser,
        body: {
          sessionId: authUser.sessionId,
          dataCenter: authUser.dataCenter
        }
      }
    )

    if (response instanceof AppError) {
      throw new Error(response.errorMsg)
    }
    return response
  }

  /**
   * Returns a list of the companies that the provided user has access to.
   *
   * @param userId The user's id to get companies for
   * @param sessionId The user's current session id, retrieved back from logging in
   */
  static async getCompanies(authUser: AuthenticatedUser): Promise<Company[]> {
    const companyResponse = await get<{data: Company[]}>(
      {
        route: '/cmccompanies',
        user: authUser
      }
    )

    if (companyResponse instanceof AppError) {
      throw new Error(companyResponse.errorMsg)
    }
    return companyResponse.data.sort((a: Company, b: Company) => {
      if (a.name > b.name) {
        return 1
      }
      if (a.name < b.name) {
        return -1
      }
      return 0
    })
  }

  static async getSelectedCompanyTags(): Promise<Tag[]> {
    // const tagResponse = await get<{tags: Tag[]}>(
    //   {
    //     route: '/tags',
    //     user: authUser,
    //     optionalHeaders: {
    //       companyid: companyId
    //     }
    //   }
    // )

    // if (tagResponse instanceof AppError) {
    //   throw new Error(tagResponse.errorMsg)
    // }

    return []
  }

  /**
   * @param userid The user's Id/username to log in with
   * @param sessionid: string,
   * @param datacenter The data center that this user wants to log into
   */
  static async getSites(
    authUser: AuthenticatedUser,
    /* Company ID to fetch sites for a specific company */
    companyId: string
  ): Promise<Site[]> {
    const response = await post<unknown, {data: Site[]}>(
      {
        route: 'siteinfo',
        body: {},
        user: authUser,
        optionalHeaders: {
          companyid: companyId
        }
      }
    )

    if (response instanceof AppError) {
      throw new Error(response.errorMsg)
    }

    // if the site information has no tagging information set, initialize it to an empty array
    const siteDataTags = response.data.map((site) => {
      if (site.tags == null) {
        return { ...site, tags: [] }
      }
      return { ...site }
    })

    return siteDataTags
  }
}
