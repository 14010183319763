import { ADD_HEART, FETCH_HEARTS, FETCH_HEARTS_FAILED } from './actions'

export function hearts(
  state = '',
  action: { type: string; payload: { text: string } }
) {
  const { type, payload } = action

  switch (type) {
    case ADD_HEART:
      return state + payload.text
    case FETCH_HEARTS:
      return state + payload.text
    case FETCH_HEARTS_FAILED:
      return 'FAILED'
    default:
      return state
  }
}
