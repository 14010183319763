import { Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { GridRowParams } from '@mui/x-data-grid-pro'
import { AppThunkDispatch, RootState } from '../../redux'
import {
  setSelectedDeviceInfo, setSiteReadinessBreadcrumbs, setSelectedDevice
} from '../../redux/siteReadiness/slice'
import BreadcrumbNavigation, { BreadcrumbSegment } from '../../components/BreadcrumbNavigation'
import SiteReadinessDeviceTable from './components/siteReadinessDeviceTable'
import { breadcrumbDispatchHelper } from '../../components/BreadcrumbNavigation/breadcrumbNavigationHelpers'
import { DrawerHeaderOptions } from '../../components/Drawer'
import SiteReadinessDevicesDrawer from './components/siteReadinessDevicesDrawer'

function mapStateToProps(state: RootState) {
  return {
    user: state.user,
    selectedSite: state.siteReadiness.selectedSite,
    selectedDevice: state.siteReadiness.selectedDevice,
    breadcrumbs: state.siteReadiness.breadcrumbs
  }
}

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchSetSiteReadinessBreadcrumbs: (...args: Parameters<typeof setSiteReadinessBreadcrumbs>) => dispatch(setSiteReadinessBreadcrumbs(...args)),
    dispatchSetSelectedDeviceInfo: (...args: Parameters<typeof setSelectedDeviceInfo>) => dispatch(setSelectedDeviceInfo(...args)),
    dispatchSetSelectedDevice: (...args: Parameters<typeof setSelectedDevice>) => dispatch(setSelectedDevice(...args))
  }
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type SiteReadinessDevicesProps = StateProps & DispatchProps

export function SiteReadinessDevices(
  {
    user,
    breadcrumbs,
    dispatchSetSiteReadinessBreadcrumbs,
    selectedDevice,
    selectedSite,
    dispatchSetSelectedDeviceInfo,
    dispatchSetSelectedDevice
  }: SiteReadinessDevicesProps
) {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [breadcrumbDepth] = useState<number>(2)

  const currentSegment: BreadcrumbSegment = {
    name: 'Devices',
    path: '/readiness/devices',
    order: breadcrumbDepth,
    testId: 'DevicesBreadcrumb'
  }
  useEffect(() => {
    breadcrumbDispatchHelper(
      breadcrumbs,
      currentSegment,
      (segments) => { dispatchSetSiteReadinessBreadcrumbs(segments) }
    )
  }, [breadcrumbDepth])

  if (!user.isAdmin) return <h1>Unauthorized Access</h1>
  if (selectedSite == null) return <Navigate to="/readiness" replace />

  const drawerHeaderOptions: DrawerHeaderOptions = {
    enableCloseArrow: true
  }

  const handleRowClick = (device: GridRowParams) => {
    const { row } = device
    const selectedRow = {
      deviceId: row.id,
      deviceName: row.deviceName,
      isPrereqReady: row.isPrereqReady,
      isUpgradeReady: row.isUpgradeReady
    }
    dispatchSetSelectedDevice(selectedRow)
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    dispatchSetSelectedDeviceInfo(null)
    dispatchSetSelectedDevice(null)
    setDrawerOpen(false)
  }

  return (
    <Stack
      alignItems="baseline"
      spacing={2}
      sx={{
        width:
          drawerOpen ? 'calc(100% - 350px)' : '100%'
      }}
    >
      <BreadcrumbNavigation breadcrumbs={breadcrumbs} />
      <Typography variant="h4">
        { `${selectedSite.siteName} Devices` }
      </Typography>
      <Typography variant="body1">
        This is a list displaying all reported physical devices for the selected site. Click on a device to see more details.
      </Typography>
      <SiteReadinessDeviceTable onRowClick={(e) => handleRowClick(e)} />
      {selectedDevice && (
      <SiteReadinessDevicesDrawer
        width={350}
        openDrawer={drawerOpen}
        closeDrawer={handleDrawerClose}
        drawerHeader={drawerHeaderOptions}
      />
      )}
    </Stack>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteReadinessDevices)
