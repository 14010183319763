export interface SiteDeviceData {
  deviceId: string
  deviceName: string
  isPrereqReady: boolean
  isUpgradeReady: boolean
}

export interface ProductVersion {
  productName: string
  productVersion: string
}

export enum SuccessState {
  Success = 'Success',
  Failure = 'Failure',
  Warning = 'Warning'
}

export interface DeviceDetailSummary {
  [key: string]: SuccessState
}

export interface DeviceDetailMockData {
  deviceId: string
  deviceType: string
  uaVersion: string
  uaStatus: string
  currentVersions: ProductVersion[]
  ralStatus: string
  posLicense: string
  osName: string
  osVersion: string
  osBitness: string
  sqlVersion: string
  sqlBitness: string
  dotNetVersion: string
  vcRedist2012Version: string
  vcRedist2013Version: string
  vcRedist2019Version: string
  localDirSpace: string
  iberDirSpace: string
  ram: string
  cpuModel: string
  cpuSpeed: string
  cpuCores: string
  isPrereqReady: boolean
  isUpgradeReady: boolean
}

export interface SiteData {
  siteId: string
  siteName: string
  currentVersions: ProductVersion[] // Array of product versions
  isPrereqReady: boolean
  isUpgradeReady: boolean
}

export const deviceDetailSummary: DeviceDetailSummary = {
  uaVersion: SuccessState.Failure,
  uaStatus: SuccessState.Failure,
  ralStatus: SuccessState.Failure,
  posLicense: SuccessState.Failure,
  osName: SuccessState.Failure,
  osVersion: SuccessState.Failure,
  osBitness: SuccessState.Failure,
  sqlVersion: SuccessState.Failure,
  sqlBitness: SuccessState.Failure,
  dotNetVersion: SuccessState.Failure,
  vcRedist2012Version: SuccessState.Failure,
  vcRedist2013Version: SuccessState.Failure,
  vcRedist2019Version: SuccessState.Failure,
  localDirSpace: SuccessState.Failure,
  iberDirSpace: SuccessState.Failure,
  ram: SuccessState.Failure,
  cpuModel: SuccessState.Failure,
  cpuSpeed: SuccessState.Failure,
  cpuCores: SuccessState.Failure
}

export interface DeviceDetailResponse {
  deviceDetail: DeviceDetailMockData
  deviceDetailSummary: DeviceDetailSummary
}

export interface MissingDevicesData {
  hasCmcData: boolean,
  missingDevices: string[]
}
