import React from 'react'
import { AppShell } from '@ncr-design-system/pattern-app-shell'
import { Outlet, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import Box from '@mui/material/Box'
import { linkComponent } from './link-component'
import SidebarTopItem from './SidebarTopItem'
import SidebarBottomItem from './sidebarBottomItem'
import { sidebarRoutes } from './sidebarRoutes'
import { RootState } from '../../redux'
import Appbar from './Appbar'

function mapStateToProps(state: RootState) {
  return {
    user: state.user
  }
}
export type AppShellContainerProps = ReturnType<typeof mapStateToProps>;

export function AppShellContainer({
  user
}: AppShellContainerProps): JSX.Element {
  const { pathname } = useLocation()
  const userRole = {
    isLoggedIn: user.isLoggedIn,
    isAdmin: user.isAdmin
  }

  return (
    <AppShell
      userRole={userRole}
      sidebarItems={sidebarRoutes}
      currentUrl={pathname}
      linkComponent={linkComponent}
      components={{
        appbarContents: <Appbar user={user} />,
        sidebarTopItem: <SidebarTopItem />,
        sidebarBottomItems: <SidebarBottomItem />
      }}
      sxProps={{
        appBar: {
          bgcolor: '#FFFFFF',
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
        },
        sidebarFrameWrapper: { bgcolor: '#FFFFFF' },
        content: {
          bgcolor: '#FAFAFA',
          padding: 0
        },
        sidebarItemButtonL1: {
          // First-level items (parents)
          padding: 0
        },
        sidebarItemButtonL2: {
          // Second-level items (children)
          padding: 0
        },
        navDrawerDesktop: {
          '.MuiPaper-root > div:first-of-type > div:first-of-type': {
            marginBottom: 1,
            height: 'auto'
          },
          '.MuiPaper-root > div:first-of-type': {
            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
            overflow: 'hidden' // needed to prevent scrollbar created from borderRight
          },
          '.MuiPaper-root > div:first-of-type > div:last-of-type': {
            borderTop: '1px solid rgba(0, 0, 0, 0.1)'
          }
        }
      }}
    >
      {/* Will render to the appropriate component based on the URL */}
      {/* sx props set here to properly set bgcolor when contents expand pass the vertical view.
          sxProps.content for the AppShell isn't able to account for the background past the
          vertical view. */}
      <Box
        sx={{
          bgcolor: '#FAFAFA',
          padding: 3,
          height: '100%', // allocate remaining height space to be allocated for pages
          overflow: 'auto' // if page exceeds allocated space, allow scrolling (prevents white bg from showing)
        }}
      >
        <Outlet />
      </Box>
    </AppShell>
  )
}

export default connect(mapStateToProps)(AppShellContainer)
