/**
 * Device status information
 */
export interface DeviceStatus {
  type: number;
  message: string;
  timestamp: string;
}

/**
 * Device information including list of all statuses for that device
 */
export interface Device {
  id: string;
  name: string;
  type: number;
  statuses: DeviceStatus[];
}

/**
 * Site information including devices for a given site
 */
export interface Site {
  id: string;
  name: string;
  devices: Device[];
}

/**
 * User data for the deployment
 */
export interface User {
  id: string;
  name: string;
}

/**
 * Meta data about for a deployment approval
 */
export interface DeploymentApproval {
  user: User;
  approved: boolean;
  timestamp: string;
}

/**
 * The schedule time type indicates the type of time that is being scheduled.
 */
export enum ScheduleTime {
  /** Schedule property minutesAfterEOD will contain number of minutes after site's configured EOD  */
  MinutesAfterEOD = 0,
  /** Schedule will contain a startDate and time value */
  DateTime = 1,
  /** Schedule is meant to execute immediately */
  Now = 2,
  /** Schedule is meant to only download now and install later on demand */
  DownloadNowInstallOnDemand = 3,
}

/**
 * The DeploymentType indicates the type of deployment is being scheduled.
 */
export enum DeploymentType {
  Active = 'Active',
  Saved = 'Saved',
  Completed = 'Completed',
}

/**
 * Deployment interface contains everything a site would need to know about a deployment.
 */
export interface Deployment {
  id: string;
  name: string;
  createdDate: string;
  createdBy: User;
  notes: string;
  rebootAfterDeployment: boolean;
  solutionSet: {
    id: string;
    name: string;
    products: { [ProductName: string]: string };
  };
  preInstall?: {
    reboot: boolean;
    file: string;
    fileType: string;
    fileLocation: string;
  };
  postInstall?: {
    reboot: boolean;
    file: string;
    fileType: string;
    fileLocation: string;
  };
  scheduledBy?: User;
  schedule?: {
    startDate: string;
    timeType: ScheduleTime;
    minutesAfterEOD?: number;
    time?: string;
  };
  approval?: DeploymentApproval;
  sites?: Site[];
}
