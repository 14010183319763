import CMCClient from './cmcClient'
import { AuthenticatedUser, buildAuthenticatedUser } from '../common/authenticatedUser'
import { put, removeBulk } from '../common/upgradesApiClient'
import { AppError } from '../common/result'

/**
 * Definition of a Site underneath a company.
 */
export interface Site {
  id: string;
  group: string | null;
  name: string | null;
  /** A list of tag Ids that are applied to this site */
  tags: string[];
  timeZone: string | null;
  unitNumber: number | null;
  openTime: string | null;
  eodTime: string | null;
  address: string | null;
  phone: string | null;
}

interface AssignTagsToSitesRequest {
  tagIds: string[],
  sites: string[]
}

export async function getSites(
  userId: string,
  sessionId: string,
  dataCenter: string,
  companyId: string
): Promise<Site[]> {
  const authUser = buildAuthenticatedUser(sessionId, dataCenter, userId)
  return CMCClient.getSites(authUser, companyId)
}

/**
 * Assigns a set of tags to the specified sites on the backend database
 * @param tagIds The tags to add to the specified sites
 * @param siteIds The sites to add the specified tags to
 */
export async function assignTagsToSites(
  authUser: AuthenticatedUser,
  companyId: string,
  tagIds: string[],
  siteIds: string[]
) {
  const response = await put<AssignTagsToSitesRequest, unknown>(
    {
      route: '/sites/tags',
      body: {
        tagIds,
        sites: siteIds
      },
      user: authUser,
      optionalHeaders: {
        companyid: companyId
      }
    }
  )

  if (response instanceof AppError) {
    throw new Error(response.errorMsg)
  }

  return { tagIds, siteIds }
}

interface RemoveTagsFromSitesRequest {
  tagIds: string[],
  sites: string[]
}

/**
 * Removes a set of tags from the specified sites on the backend database
 * @param tagIds The tags to add to the specified sites
 * @param siteIds The sites to add the specified tags to
 */
export async function removeTagsFromSites(
  authUser: AuthenticatedUser,
  companyId: string,
  tagIds: string[],
  siteIds: string[]
) {
  const response = await removeBulk<RemoveTagsFromSitesRequest, unknown>(
    {
      route: '/sites/tags',
      user: authUser,
      optionalHeaders: {
        companyid: companyId
      },
      body: {
        tagIds,
        sites: siteIds
      }
    }
  )

  if (response instanceof AppError) {
    throw new Error(response.errorMsg)
  }
  return { tagIds, siteIds }
}
