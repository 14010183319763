import { UserState } from '../redux/users/reducers'

export interface AuthenticatedUser {
  id: string
  dataCenter: string
  sessionId: string
}

export function buildAuthenticatedUser(
  sessionId: string | undefined | null,
  dataCenter: string | undefined | null,
  userId: string | undefined | null
): AuthenticatedUser {
  return {
    id: userId ?? '',
    dataCenter: dataCenter ?? '',
    sessionId: sessionId ?? ''
  }
}

export function buildAuthenticatedUserFromState(
  userState: UserState
): AuthenticatedUser {
  return buildAuthenticatedUser(
    userState.sessionId,
    userState.dataCenter,
    userState.userId
  )
}
