import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CancelToken } from 'axios'
import {
  DeviceDetailResponse,
  SiteDeviceData,
  SiteData,
  MissingDevicesData
} from '../../data/siteReadinessData'
import { buildAuthenticatedUser } from '../../common/authenticatedUser'
import {
  getMissingDevices, getSiteReadinessDevices, getSiteReadinessDevicesDrawer, getSiteReadinessSites
} from '../../data/siteReadiness'
import { RequestState } from '../common'
import { BreadcrumbSegment } from '../../components/BreadcrumbNavigation'

/**
 * Retrieves the sites for the site readiness page
 */
export const siteReadiness = createAsyncThunk(
  'siteReadiness/siteReadiness',
  async (args: {
    userId: string
    sessionId: string
    dataCenter: string
    companyid: string | undefined
  }): Promise<SiteData[]> => {
    const {
      userId,
      sessionId,
      dataCenter,
      companyid
    } = args
    const authUser = buildAuthenticatedUser(sessionId, dataCenter, userId)
    if (companyid == null) {
      throw new Error('Site readiness requires a company id')
    }
    return getSiteReadinessSites(authUser, companyid)
  }
)

/**
 * Retrieves the devices for a site for site readiness.
 */
export const siteReadinessDevices = createAsyncThunk(
  'siteReadiness/siteReadinessDevices',
  async (args: {
    userId: string
    sessionId: string
    dataCenter: string
    siteId: string | undefined
    abortSignal: CancelToken
  }): Promise<SiteDeviceData[]> => {
    const {
      userId, sessionId, dataCenter, siteId, abortSignal
    } = args
    const authUser = buildAuthenticatedUser(sessionId, dataCenter, userId)
    if (siteId == null) {
      throw new Error('Site ID must be provided')
    }
    return getSiteReadinessDevices(abortSignal, authUser, siteId)
  }
)

export const getDeviceInfo = createAsyncThunk(
  'siteReadiness/getDeviceInfo',
  async (args: {
    userId: string
    sessionId: string
    dataCenter: string
    siteId: string | undefined
    deviceId: string | undefined
    abortSignal: CancelToken
  }): Promise<DeviceDetailResponse> => {
    const {
      userId, sessionId, dataCenter, siteId, deviceId, abortSignal
    } = args
    const authUser = buildAuthenticatedUser(sessionId, dataCenter, userId)
    if (siteId == null) {
      throw new Error('Site ID must be provided')
    }
    if (deviceId == null) {
      throw new Error('Device ID must be provided')
    }
    return getSiteReadinessDevicesDrawer(abortSignal, authUser, siteId, deviceId)
  }
)

export const missingDevices = createAsyncThunk(
  'siteReadiness/getMissingDevices',
  async (args: {
    userId: string
    sessionId: string
    dataCenter: string
    siteId: string | undefined
    abortSignal: CancelToken
  }): Promise<MissingDevicesData> => {
    const {
      userId, sessionId, dataCenter, siteId, abortSignal
    } = args
    const authUser = buildAuthenticatedUser(sessionId, dataCenter, userId)
    if (siteId == null) {
      throw new Error('Site ID must be provided')
    }
    return getMissingDevices(abortSignal, authUser, siteId)
  }
)

export interface SiteReadinessState {
  sites: SiteData[],
  siteReadinessRequestState: RequestState,
  selectedSite: SiteData | null,
  selectedDevice: SiteDeviceData | null,
  siteDevicesRequestState: RequestState,
  devices: SiteDeviceData[],
  deviceInfo: DeviceDetailResponse | null,
  deviceInfoRequestState: RequestState,
  missingDevicesRequestState: RequestState,
  missingDevices: MissingDevicesData | null,
  breadcrumbs: BreadcrumbSegment[]
}

const initialState: SiteReadinessState = {
  sites: [],
  siteReadinessRequestState: 'idle',
  selectedSite: null,
  selectedDevice: null,
  siteDevicesRequestState: 'idle',
  devices: [],
  deviceInfo: null,
  deviceInfoRequestState: 'idle',
  missingDevicesRequestState: 'idle',
  missingDevices: null,
  breadcrumbs: []
}

export const siteReadinessSlice = createSlice({
  name: 'siteReadiness',
  initialState,
  reducers: {
    setSiteReadinessRequestState: (
      state,
      action: PayloadAction<RequestState>
    ) => {
      state.siteReadinessRequestState = action.payload
    },
    setSiteDevicesRequestState: (
      state,
      action: PayloadAction<RequestState>
    ) => {
      state.siteDevicesRequestState = action.payload
    },
    setMissingDevicesRequestState: (
      state,
      action: PayloadAction<RequestState>
    ) => {
      state.missingDevicesRequestState = action.payload
    },
    setSelectedSite: (
      state,
      action: PayloadAction<SiteData | null>
    ) => {
      state.selectedSite = action.payload
    },
    setSelectedDevice: (
      state,
      action: PayloadAction<SiteDeviceData | null>
    ) => {
      state.selectedDevice = action.payload
    },
    setSelectedDeviceInfo: (
      state,
      action: PayloadAction<DeviceDetailResponse | null>
    ) => {
      state.deviceInfo = action.payload
    },
    setSiteReadinessBreadcrumbs: (state, action: PayloadAction<BreadcrumbSegment[]>) => {
      state.breadcrumbs = [...action.payload].sort((a, b) => a.order - b.order)
    },
    setSiteReadinessDevices: (
      state,
      action: PayloadAction<SiteDeviceData[]>
    ) => {
      state.devices = action.payload
    },
    setMissingDevices: (
      state,
      action: PayloadAction<MissingDevicesData | null>
    ) => {
      state.missingDevices = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(siteReadiness.pending, (state) => {
      state.siteReadinessRequestState = 'pending'
    })
    builder.addCase(siteReadiness.rejected, (state) => {
      state.siteReadinessRequestState = 'rejected'
    })
    builder.addCase(siteReadiness.fulfilled, (state, action) => {
      state.sites = action.payload
      state.siteReadinessRequestState = 'fulfilled'
    })
    builder.addCase(siteReadinessDevices.pending, (state) => {
      state.siteDevicesRequestState = 'pending'
    })
    builder.addCase(siteReadinessDevices.rejected, (state) => {
      // * Note: Set the request state to pending so the site devices drawer table continues to spin for the new request.
      state.siteDevicesRequestState = 'pending'
    })
    builder.addCase(siteReadinessDevices.fulfilled, (state, action) => {
      state.devices = action.payload
      state.siteDevicesRequestState = 'fulfilled'
    })
    builder.addCase(getDeviceInfo.pending, (state) => {
      state.deviceInfoRequestState = 'pending'
    })
    builder.addCase(getDeviceInfo.rejected, (state) => {
      // * Note: Set the request state to pending so the devices drawer continues to show loading for the new request.
      state.deviceInfoRequestState = 'pending'
    })
    builder.addCase(getDeviceInfo.fulfilled, (state, action) => {
      state.deviceInfo = action.payload
      state.deviceInfoRequestState = 'fulfilled'
    })
    builder.addCase(missingDevices.pending, (state) => {
      state.missingDevicesRequestState = 'pending'
    })
    builder.addCase(missingDevices.rejected, (state) => {
      state.missingDevicesRequestState = 'pending'
    })
    builder.addCase(missingDevices.fulfilled, (state, action) => {
      state.missingDevices = action.payload
      state.missingDevicesRequestState = 'fulfilled'
    })
  }
})

export default siteReadinessSlice.reducer
export const {
  setSiteReadinessRequestState,
  setSiteDevicesRequestState,
  setSelectedSite,
  setSelectedDevice,
  setSelectedDeviceInfo,
  setMissingDevices,
  setMissingDevicesRequestState,
  setSiteReadinessBreadcrumbs,
  setSiteReadinessDevices
} = siteReadinessSlice.actions
