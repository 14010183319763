import { BreadcrumbSegment } from '.'

export const breadcrumbDispatchHelper = (stateSegments: BreadcrumbSegment[], currentSegment: BreadcrumbSegment, dispatcher: (segments: BreadcrumbSegment[]) => void) => {
  const segments = stateSegments.filter((b) => b.order <= currentSegment.order)
  const matchingCurrentSegments = segments.filter((b) => b.order === currentSegment.order)
  if (matchingCurrentSegments.length > 1) {
    throw new Error('Breadcrumbs component must have unique segment depths')
  }

  const hasCurrentSegment = matchingCurrentSegments.length === 1
  if (!hasCurrentSegment) segments.push(currentSegment)

  dispatcher(segments)
}
