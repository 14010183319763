import React from 'react'
import { Stack, Typography } from '@mui/material'
import { connect } from 'react-redux'
import { RootState } from '../../../redux'

function mapStateToProps(state: RootState) {
  return {
    missingDevices: state.siteReadiness.missingDevices
  }
}

type StateProps = ReturnType<typeof mapStateToProps>
type MissingDevicesSummaryProps = StateProps

export function MissingDevicesSummary({
  missingDevices
} : Readonly<MissingDevicesSummaryProps>): JSX.Element {
  if (missingDevices == null) {
    return (
      <Typography />
    )
  }

  if (!missingDevices.hasCmcData) {
    return (
      <Stack>
        <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
          Summary
        </Typography>
        <Typography variant="body1" color="orange" paddingTop={1}>
          Device information missing for comparison.
          Please verify devices displayed are complete. If any devices are missing from the list, please ensure they have Pulse Streaming Edge Agent installed.
        </Typography>
      </Stack>
    )
  }

  if (missingDevices.hasCmcData && missingDevices.missingDevices.length === 0) {
    return (
      <Typography />
    )
  }

  return (
    <Stack>
      <Stack>
        <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
          Summary
        </Typography>
        <Typography variant="body1" color="red" paddingTop={1}>
          This site is missing data from the following devices:
        </Typography>
        <Stack>
          {missingDevices.missingDevices.map((device) => (
            <Typography variant="body1" color="red" paddingTop={1} paddingLeft={1}>
              {`• ${device}`}
            </Typography>
          ))}
        </Stack>
        <Typography variant="body1" color="red" paddingTop={1}>
          Please ensure all FOH devices have Pulse Streaming Edge Agent installed.
        </Typography>
      </Stack>
    </Stack>
  )
}

export default connect(mapStateToProps)(MissingDevicesSummary)
