import React from 'react'
import { RouteObject, useRoutes, Navigate } from 'react-router-dom'
import AppShell from './components/AppShell'
import LoginPage from './pages/LoginPage'
import { routes, Route } from './routes'
import { SelectCompanyPage } from './pages/SelectCompanyPages'

/**
 * Helper function to set up children routes for the useRoutes hook
 * provided by React Router
 */
export function mapChildrenRoutes(
  childrenRoutes: Route[]
): RouteObject[] | undefined {
  return childrenRoutes.map((route) => ({
    path: route.path,
    element: route.pageElement,
    children: route.children ? mapChildrenRoutes(route.children) : undefined
  }))
}
/**
 * A version of routes that is mapped for specific usage in the App component,
 * to be passed into the useRoutes hook provided by React Router.
 */
export const appRoutes: RouteObject[] = [
  {
    element: <LoginPage />,
    index: true
  },
  {
    element: <SelectCompanyPage />,
    path: '/select-company'
  },
  {
    element: <AppShell />,
    children: mapChildrenRoutes(routes)
  },
  {
    // ! Temporarily commenting out all the tabs except for Site Readiness (ECS-5373)
    // ! Note: Remove these (!) comments when we reintroduce the pages
    element: <Navigate to="/readiness" replace />,
    path: '*'
  }
]

export default function App() {
  return useRoutes(appRoutes)
}
