import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  Site,
  getSites,
  assignTagsToSites,
  removeTagsFromSites
} from '../../data/sites'
import { LOGOUT } from '../users/actions'
import { AuthenticatedUser } from '../../common/authenticatedUser'
import { BreadcrumbSegment } from '../../components/BreadcrumbNavigation'

export interface SitesState {
  sites: {
    [siteId: string]: Site
  }
  requestSitesState: 'idle' | 'pending' | 'fulfilled' | 'rejected'
  breadcrumbs: BreadcrumbSegment[]
}

const initialState: SitesState = {
  sites: {},
  requestSitesState: 'idle',
  breadcrumbs: []
}

// Thunks
export const getSitesData = createAsyncThunk(
  'sites/getSites',
  async (credentials: {
    userId: string;
    sessionId: string;
    dataCenter: string;
    companyId: string;
  }) => {
    const {
      userId, sessionId, dataCenter, companyId
    } = credentials
    const sites = await getSites(userId, sessionId, dataCenter, companyId)
    return sites
  }
)

export const assignTags = createAsyncThunk(
  'sites/assignTags',
  async (args: {
    authUser: AuthenticatedUser,
    companyId: string,
    tagIds: string[],
    siteIds: string[]
  }) => {
    const {
      authUser, companyId, tagIds, siteIds
    } = args
    const updatedSites = await assignTagsToSites(
      authUser,
      companyId,
      tagIds,
      siteIds
    )
    return updatedSites
  }
)

export const removeTags = createAsyncThunk(
  'sites/removeTags',
  async (args: {
    authUser: AuthenticatedUser,
    companyId: string,
    tagIds: string[],
    siteIds: string[]
  }) => {
    const {
      authUser, companyId, tagIds, siteIds
    } = args
    const updatedSites = await removeTagsFromSites(
      authUser,
      companyId,
      tagIds,
      siteIds
    )
    return updatedSites
  }
)

export const sitesSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    setTags: (state, action: PayloadAction<{ siteId: string; tags: string[] }>) => {
      const { siteId, tags } = action.payload
      state.sites[siteId].tags = tags
    },
    setSiteTagsBreadcrumbs: (state, action: PayloadAction<BreadcrumbSegment[]>) => {
      state.breadcrumbs = [...action.payload].sort((a, b) => a.order - b.order)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(LOGOUT, (state) => {
      state.sites = {}
      state.requestSitesState = 'idle'
    })

    builder.addCase(getSitesData.pending, (state) => {
      state.requestSitesState = 'pending'
    })

    builder.addCase(getSitesData.fulfilled, (state, action) => {
      state.requestSitesState = 'fulfilled'
      state.sites = {} // reset
      action.payload.forEach((site) => {
        state.sites[site.id] = site
      })
    })

    builder.addCase(getSitesData.rejected, (state) => {
      state.requestSitesState = 'rejected'
      state.sites = {}
    })

    builder.addCase(assignTags.pending, () => {
      // TODO
      // The call is updating
    })

    builder.addCase(assignTags.rejected, () => {
      // TODO
      // The call failed so the backend was not updated
    })

    builder.addCase(assignTags.fulfilled, (state, action) => {
      const { siteIds, tagIds } = action.payload
      siteIds.forEach((siteId) => {
        tagIds.forEach((tagId) => {
          if (state.sites[siteId].tags.includes(tagId)) {
            return
          }
          state.sites[siteId].tags.push(tagId)
        })
      })
    })

    builder.addCase(removeTags.fulfilled, (state, action) => {
      const { siteIds, tagIds } = action.payload
      siteIds.forEach((siteId) => {
        tagIds.forEach((tagId) => {
          state.sites[siteId].tags = state.sites[siteId].tags.filter(
            (t) => t !== tagId
          )
        })
      })
    })
  }
})

export const sitesReducer = sitesSlice.reducer
export const {
  setTags,
  setSiteTagsBreadcrumbs
} = sitesSlice.actions
